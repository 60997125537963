import { createRouter, createWebHistory } from "vue-router";

import Survey from "@/components/Survey.vue";
import AdminLogin from "../components/AdminLogin.vue";
import AdminHome from "@/components/AdminHome.vue";

const routes = [
  { path: "/", component: Survey },
  { path: "/login", component: AdminLogin },
  { path: "/admin", component: AdminHome },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
