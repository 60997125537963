import { defineStore } from "pinia";
import { storage } from "../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

export const useStorageStore = defineStore("storage", {
  state: () => ({
    progress: 0,
  }),
  actions: {
    uploadFile(blob, path, metadata = null) {
      console.log(blob, path);
      return new Promise((resolve, reject) => {
        const storageRef = ref(storage, path);

        let uploadTask;
        if (metadata) {
          uploadTask = uploadBytesResumable(storageRef, blob, metadata);
        } else {
          uploadTask = uploadBytesResumable(storageRef, blob);
        }

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + this.progress + "% done");
          },
          (error) => {
            console.error(error);
            reject(false);
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    },
  },
  getters: {},
});
