import { defineStore } from "pinia";
import { db } from "../firebase";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  addDoc,
  query,
  getDocs,
  where,
  getCountFromServer,
} from "firebase/firestore";

export const useDBStore = defineStore("db", {
  state: () => ({}),
  actions: {
    addDocument(col, data, id) {
      return new Promise((resolve, reject) => {
        if (id) {
          setDoc(doc(db, col, id), data)
            .then(() => {
              resolve(id);
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
              reject(false);
            });
        } else {
          addDoc(collection(db, col), data)
            .then((docRef) => {
              resolve(docRef.id);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              reject(false);
            });
        }
      });
    },
    deleteDocument(collection, docId) {
      return new Promise((resolve, reject) => {
        deleteDoc(doc(db, collection, docId))
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
            reject(false);
          });
      });
    },
    updateDocument(col, docId, data) {
      return new Promise((resolve, reject) => {
        const docRef = doc(db, col, docId);

        updateDoc(docRef, data)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
            reject(false);
          });
      });
    },
    updateDocumentField(collection, document, field, value) {
      return new Promise((resolve, reject) => {
        const userRef = doc(db, collection, document);

        updateDoc(userRef, { [field]: value })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
            reject(false);
          });
      });
    },
    getDocument(collection, docId) {
      console.log("READ_FST: ", collection, docId);
      return new Promise((resolve, reject) => {
        getDoc(doc(db, collection, docId))
          .then((doc) => {
            if (doc.exists()) {
              resolve(doc.data());
            } else {
              console.log("No such document!");
              resolve({});
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
            reject(false);
          });
      });
    },
    getDocuments(col, q) {
      console.log("READ_FST: ", col, q);
      return new Promise((resolve, reject) => {
        const colRef = collection(db, col);

        if (!q) {
          getDocs(colRef)
            .then((querySnapshot) => {
              const docs = [];
              querySnapshot.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id;
                docs.push(data);
              });
              resolve(docs);
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
              reject(false);
            });
          return;
        } else {
          const queryObj = query(colRef, where(q[0], q[1], q[2]));

          getDocs(queryObj)
            .then((querySnapshot) => {
              const docs = [];
              querySnapshot.forEach((doc) => {
                docs.push(doc.data());
              });
              resolve(docs);
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
              reject(false);
            });
        }
      });
    },
    countDocuments(col) {
      console.log("COUNT_READ_FST: ", col);
      return new Promise((resolve, reject) => {
        const collectionRef = collection(db, col);
        getCountFromServer(query(collectionRef))
          .then((snapshot) => {
            const count = snapshot.data().count;
            console.log("Document count:", count);
            resolve(count);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
            reject(false);
          });
      });
    },
  },
});
