<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <nav class="bg-gray-900">
    <div
      class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 cursor-pointer"
    >
      <a
        @click="$router.push('/')"
        class="flex items-center space-x-3 rtl:space-x-reverse"
      >
        <img
          src="../assets/gvglogo.jpeg"
          class="h-8 rounded-full w-auto"
          alt="GVG Logo"
        />
        <span
          class="self-center text-2xl font-semibold whitespace-nowrap text-white md:block hidden"
          >Grupo del Valle Garbal</span
        >
      </a>
      <button
        data-collapse-toggle="navbar-default"
        type="button"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-default"
        aria-expanded="false"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
      <div class="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul
          class="flex flex-col p-4 md:p-0 mt-4 font-semibold border rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 bg-gray-800 md:bg-gray-900 border-gray-700"
        >
          <li v-for="item in menuItems" :key="item.name">
            <a
              class="block py-2 px-3 rounded hover:bg-gray-100 cursor-pointer md:hover:bg-transparent md:border-0 md:hover:text-blue-500 md:p-0 text-white"
              @click="$router.push(item.link)"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      menuItems: [
        { name: "Encuesta", link: "/" },
        { name: "Administrar", link: "/login" },
      ],
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
