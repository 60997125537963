<template>
  <main class="flex flex-col min-h-screen bg-blue-200">
    <Navbar />

    <RouterView class="mx-auto" />

    <footer
      class="bottom-0 w-full flex flex-col space-y-4 text-center text-white font-semibold border-gray-200 bg-gray-900 p-4"
    >
      <p>Las Alamedas, Atizapán de Zaragoza - 56 1371 7848</p>
      <p>
        <a
          href="https://gvgarbal.com/"
          class="text-blue-300 hover:text-blue-500"
        >
          Grupo del Valle Garbal © 2024
        </a>
        - Todos los derechos reservados
      </p>
    </footer>
  </main>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
