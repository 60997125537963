<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container mx-auto flex flex-col items-center">
    <Suspense>
      <SurveyForm />

      <!-- loading state via #fallback slot -->
      <template #fallback>
        <Wave title="Cargando" msg="Porfavor espera" class="m-2" />
      </template>
    </Suspense>
  </div>
</template>

<script>
import Wave from "./LoadingWave.vue";
import SurveyForm from "./SurveyForm.vue";

export default {
  components: {
    Wave,
    SurveyForm,
  },
};
</script>
