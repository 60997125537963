<template>
  <div class="container justify-center p-2">
    <div class="grid gap-1 md:grid-cols-2 md:gap-2 font-bold">
      <div class="bg-white box p-4">
        <p class="title">Editar/Crear nueva encuesta</p>
        <input
          class="w-full border border-gray-300 rounded-lg p-2 text-sm mb-2"
          type="text"
          v-model="id"
          placeholder="ID de la encuesta (sin espacios y en minúsculas)"
        />
        <textarea
          class="w-full border border-gray-300 rounded-lg p-2 text-sm"
          placeholder="Pega aquí el JSON de la encuesta"
          rows="30"
          v-model="json"
        ></textarea>

        <div class="flex space-x-2">
          <button class="button bg-blue-500" @click="createSurvey">
            Crear Encuesta
          </button>
          <button class="button bg-red-500" @click="clearFields">Borrar</button>
        </div>
      </div>
      <div class="bg-white box p-4">
        <div class="box bg-white p-2 space-y-1 mb-5">
          <p class="title">Encuesta actual</p>

          <p class="font-bold text-xl bg-green-200 p-2 box">
            {{ currentSurvey.id }}
          </p>

          <p class="font-bold text-xl bg-blue-200 p-2 box">
            Conteo: {{ surveyCount }}
          </p>

          <div class="flex space-x-1">
            <button @click="$router.push('/')" class="button bg-blue-600">
              Ver Encuesta
            </button>

            <button @click="exportData" class="button bg-green-600">
              Exportar Resultados
            </button>
          </div>
        </div>

        <p class="title">Otras Encuestas</p>

        <div class="overflow-y-auto h-96">
          <div v-for="survey in surveys" :key="survey.id">
            <div
              class="flex flex-row justify-between items-center border-b border-gray-300 p-2"
              v-if="survey.id !== 'production'"
            >
              <p class="font-bold">{{ survey.id }}</p>

              <div class="flex flex-row items-center space-x-2">
                <p class="text-sm">
                  {{ survey.created.slice(0, 10) }}
                </p>
                <button
                  type="button"
                  @click="updateCurrentSurvey(survey.id)"
                  class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                >
                  <svg
                    class="w-4 h-4 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 17h3a3 3 0 0 0 0-6h-.025a5.56 5.56 0 0 0 .025-.5A5.5 5.5 0 0 0 7.207 9.021C7.137 9.017 7.071 9 7 9a4 4 0 1 0 0 8h2.167M12 19v-9m0 0-2 2m2-2 2 2"
                    />
                  </svg>
                </button>

                <button
                  @click="editSurvey(survey)"
                  type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                >
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </button>

                <button
                  type="button"
                  @click="deleteSurvey(survey.id)"
                  class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                >
                  <svg
                    class="w-4 h-4 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div v-if="surveys.length === 0" class="text-center">
            <p>No hay encuestas creadas</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useDBStore } from "@/stores/db";

export default {
  setup() {
    const db = useDBStore();
    const state = reactive({
      id: "",
      json: "",
      surveys: [],
      currentSurvey: {},
      surveyCount: 0,
      allSurveys: [],
    });

    async function createSurvey() {
      try {
        const survey = JSON.parse(state.json);
        const created = new Date().toISOString();
        const data = { survey: { ...survey }, created: created };
        await db.addDocument("surveys", data, state.id);
        alert("Encuesta creada correctamente");
        state.id = "";
        state.json = "";
        getSurveys();
      } catch (error) {
        console.error("Error parsing JSON", error);
        alert("Error al crear la encuesta, revisar consola");
      }
    }

    async function getSurveys() {
      state.surveys = await db.getDocuments("surveys");
      state.currentSurvey = await db.getDocument("production", "survey");
      state.surveyCount = await db.countDocuments(
        "results-" + state.currentSurvey.id
      );
    }

    async function deleteSurvey(id) {
      let confirm = window.confirm("¿Estás seguro de eliminar la encuesta?");
      if (!confirm) return;

      try {
        await db.deleteDocument("surveys", id);
        alert("Encuesta eliminada correctamente");
        getSurveys();
      } catch (error) {
        console.error("Error deleting survey", error);
        alert("Error al eliminar la encuesta, revisar consola");
      }
    }

    async function updateCurrentSurvey(id) {
      let confirm = window.confirm(
        "¿Estás seguro de actualizar la encuesta actual?"
      );
      if (!confirm) return;

      let newSurvey = state.surveys.find((survey) => survey.id === id);

      try {
        await db.updateDocument("production", "survey", newSurvey);
        alert("Encuesta actualizada correctamente");
      } catch (error) {
        console.error("Error updating current survey", error);
        alert("Error al actualizar la encuesta actual, revisar consola");
      }
      getSurveys();
    }

    async function exportData() {
      try {
        state.allSurveys = await db.getDocuments(
          "results-" + state.currentSurvey.id
        );

        let obj = { answers: state.allSurveys };
        let content = JSON.stringify(obj);
        let contentType = "text/plain";
        let a = document.createElement("a");
        let file = new Blob([content], {
          type: contentType,
        });
        a.href = URL.createObjectURL(file);
        a.download = "Data.json";
        a.click();
      } catch (error) {
        console.log(error);
      }
    }

    async function editSurvey(s) {
      state.id = s.id;
      state.json = JSON.stringify(s.survey, null, 2);
    }

    function clearFields() {
      state.id = "";
      state.json = "";
    }

    getSurveys();

    return {
      createSurvey,
      deleteSurvey,
      clearFields,
      exportData,
      editSurvey,
      updateCurrentSurvey,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
