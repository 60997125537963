<template>
  <div class="container mx-auto flex flex-col items-center px-2">
    <div class="flex flex-col items-center space-y-2 box bg-white m-2 p-2">
      <p class="title m-0">
        {{ data.survey.title }}
      </p>

      <div class="flex items-center space-x-2 w-full">
        <!-- Pollster select -->
        <select
          name="pollster"
          v-model="pollster"
          class="box"
          placeholder="Ecads"
        >
          <option value="0" selected="selected" disabled>Encuestador</option>
          <option v-for="pollster in 15" :key="pollster" class="font-bold">
            {{ pollster }}
          </option>
        </select>

        <!-- Location -->
        <button
          @click="getLocation"
          class="w-10 h-10 rounded-xl flex items-center justify-center p-2"
          :class="lat !== 0 && lon !== 0 ? 'bg-green-700' : 'bg-blue-700'"
        >
          <svg
            class="w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <!-- Image picker -->
        <button
          @click="$refs.fileInput.click()"
          class="w-10 h-10 rounded-xl flex items-center justify-center p-2"
          :class="fileName !== '' ? 'bg-green-700' : 'bg-blue-700'"
        >
          <svg
            class="w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
            />
          </svg>
        </button>

        <!-- Image picker -->
        <input
          hidden
          type="file"
          ref="fileInput"
          class="w-full"
          accept="image/*"
          @change="onFilePicked"
        />
      </div>
    </div>

    <SurveyComponent :model="survey" class="bg-blue-200 w-full" />
  </div>
</template>

<script>
import { Model } from "survey-core";
import { reactive, toRefs } from "vue";
import "survey-core/defaultV2.min.css";
import { ContrastLight } from "survey-core/themes/contrast-light";
import { useDBStore } from "@/stores/db";
import { useStorageStore } from "@/stores/storage";

export default {
  async setup() {
    const db = useDBStore();
    const storage = useStorageStore();
    const data = await db.getDocument("production", "survey");
    const elements = { elements: [...data.survey.pages[0].elements] };

    const survey = new Model(elements);
    survey.applyTheme(ContrastLight);
    survey.onComplete.add(surveyComplete);

    const state = reactive({
      pollster: 0,
      lat: 0,
      lon: 0,
      fileName: "",
      file: null,
      answers: {},
    });

    function onFilePicked(event) {
      state.fileName = Date.now() + "-" + state.pollster + ".jpg";
      state.file = event.target.files[0];
    }

    function getLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          state.lat = position.coords.latitude;
          state.lon = position.coords.longitude;

          alert("Ubicación obtenida correctamente");
        },
        (error) => {
          console.log(error.message);
        }
      );
    }

    async function surveyComplete(sender) {
      if (state.pollster === 0) {
        alert("Seleccionar número de encuestador");
        survey.clear(false, true);
        return;
      }

      var ts = new Date();
      state.answers = sender.data;
      const otherData = {
        pollster: state.pollster,
        imgID: state.fileName,
        location: {
          lat: state.lat,
          lon: state.lon,
        },
        timestamp: ts.toGMTString(),
      };

      const output = { data: otherData, answers: state.answers };
      console.log(output);

      if (confirm("Deseas enviar la encuesta?")) {
        try {
          // save data
          await db.addDocument("results-" + data.id, output);
          // upload image
          await storage.uploadFile(state.file, data.id + "/" + state.fileName);
          // clear survey
          survey.clear(true, true);
          state.fileName = "";
          state.file = "";
          alert("Encuesta enviada correctamente");
        } catch (error) {
          console.log(error);
          alert("Error al enviar la encuesta");
        }
      } else {
        survey.clear(false, true);
      }
    }

    return {
      getLocation,
      onFilePicked,
      data,
      survey,
      ...toRefs(state),
    };
  },
};
</script>

<style>
.sd-input,
.sd-dropdown,
.sd-dropdown--empty {
  background-color: white !important;
}

span.sd-element__num,
span.sv-string-viewer {
  font-weight: bold !important;
  font-size: medium !important;
  z-index: 0;
}

.sd-element--with-frame,
.sd-element--with-frame.sd-question--title-top {
  padding: 2% 5% 5% 6% !important;
}

.sd-body,
.sd-body--static {
  padding: 0.5% !important;
}

.sd-dropdown__filter-string-input {
  color: black !important;
  z-index: -1;
  /* width: 0% !important; */
}

.sd-body.sd-body--static .sd-body__navigation {
  padding: 3% 0% 3% 0% !important;
}
</style>
