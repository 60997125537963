// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Your web app's Firebase configuratioN
const firebaseConfig = {
  apiKey: "AIzaSyD6jIjAq2yLeQU5JNRQd8fzC0qiVqx4B9E",
  authDomain: "gvg-surveys.firebaseapp.com",
  projectId: "gvg-surveys",
  storageBucket: "gvg-surveys.appspot.com",
  messagingSenderId: "435903651126",
  appId: "1:435903651126:web:c44faaaa67af0639abe797",
  measurementId: "G-EGKK9EZ84K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, auth, storage };
