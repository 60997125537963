<template>
  <div class="flex justify-center items-center h-screen w-full">
    <form
      class="bg-white shadow-md rounded-2xl p-6 w-1/3 text-xl font-bold space-y-4"
    >
      <div>
        <label class="text-gray-700" for="username"> Correo </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="username"
          type="text"
          autocomplete="username"
          placeholder="xyz@gmail.com"
          v-model="username"
        />
      </div>
      <div>
        <label class="text-gray-700" for="password"> Contraseña </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="password"
          type="password"
          autocomplete="current-password"
          v-model="password"
          placeholder="**********"
        />
      </div>
      <button
        class="bg-blue-500 w-full hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="button"
      >
        Iniciar sesión
      </button>
    </form>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
      username: "",
      password: "",
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
